import React, { useEffect, useState } from "react";
import classNames from 'classnames';

import HeaderImage from '../../components/imageHeader';

import { graphql } from 'gatsby';
import { useIntl } from "gatsby-plugin-intl";

import Layout from "../../components/layout";

import ReactMarkdown from 'react-markdown';

import '../../styles/index.css';
import styles from '../../styles/consulting.module.css';
import styleser from '../../styles/servico.module.css';

const AvaliacaoAgioAquisicaoPage = ({ data }) => {

    const intl = useIntl();
    // const dataValue = data.allStrapiMaterias.edges[2].node;

    const [dataValue, setDataValue] = useState({})

    useEffect(() => {
        data.allStrapiMaterias.edges.map((item) => {
            console.log(`"${item.node.tituloPt}"`)

            if (item.node.tituloPt === "MÉTODOS DE AVALIAÇÃO DE EMPRESAS") {
                setDataValue(item.node)
            }
        })
    }, [])

    const titulo = intl.locale === 'en' ? dataValue.tituloEn : dataValue.tituloPt;
    const texto = intl.locale === 'en' ? dataValue.textoEn : dataValue.textoPt;

    return (
        <Layout titlePage={titulo}>

            <HeaderImage title={titulo} />

            <div className={classNames("container", styles.consultingContainer)}>
                <main>
                    <ReactMarkdown
                        className={styleser.boxServicoImg}
                        source={texto}
                        transformImageUri={
                            uri => uri.startsWith('http') ?
                                uri : `${process.env.GATSBY_API_URL}${uri}`
                        }
                    />
                </main>
            </div>
        </Layout>
    )
}

export const query = graphql`
query {
    allStrapiMaterias {
      edges {
        node {
            colaboradores {
                nome
                foto {
                    name
                    localFile {
                      publicURL
                    }
                }
                textoPt
                textoEn
                linkedinLink
            }
          tag
          textoEn
          textoPt
          tituloEn
          tituloPt
          dependence
          id
        }
      }
    }
}
`

export default AvaliacaoAgioAquisicaoPage;
